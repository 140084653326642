import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un capell hemisfèric que posteriorment torna convex de grans dimensions, que arriben de 15 a 20 cm de diàmetre, color marró obscur. Davall el capell presenta una capa de tubs al principi blancs tornant groc-verdosos. Presenten un peu central, curt, gruixat i clarament engrossit cap a la base de color beix a marró clar. Les espores són de color groc oliva en massa, fusiformes, de 13-15 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      